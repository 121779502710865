














































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["dates"]),
    disableCampaign() {
      return !this.dates.isActive;
    },
  },
  data: () => ({}),
});
