import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/legal",
    name: "Legal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Legal.vue"),
  },
  {
    path: "/reglement",
    name: "Legal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rules" */ "../views/Rules.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    store.commit("TOKEN", to.query.token);
  }
  if (to.query.step && typeof parseInt(to.query.step as string) === "number") {
    // If not active and trying to access step 2 or 3
    if (parseInt(to.query.step as string) === 3 && !store.state.coupon.token) {
      next(false);
      return;
    }
    if (parseInt(to.query.step as string) < 4) {
      store.commit("CURRENT_STEP", parseInt(to.query.step as string));
    }
  }
  next();
});

export default router;
