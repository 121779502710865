




































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  data: () => ({
    blob: null,
    loading: false,
    dialog: false,
  }),
  computed: {
    ...mapState(["coupon"]),
  },
  methods: {
    ...mapActions(["showAlert"]),
    async downloadCouponFromToken() {
      this.loading = true;
      const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.coupon.token}`;
      // Getting BLOB from backend
      const response = await fetch(route, {
        method: "GET",
      });
      if (response.status === 200) {
        const blob = await response.blob();
        this.showAlert({
          message: "Le téléchargement du coupon va commencer, patientez...",
          color: "success",
          timeout: 3000,
        });
        const body = document.querySelector("body");
        if (body) {
          const a = document.createElement("a");
          a.style.display = "none";
          body.appendChild(a);

          // We create the URL data from the blob
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = `CCQC-coupon-associatif-${
            new Date(Date.now()).toISOString().split("T")[0]
          }.pdf`;
          setTimeout(() => {
            a.click();
          }, 3000);
        }
      } else {
        if (response.status === 404) {
          this.showAlert({
            message: "Ce coupon n'existe pas, veuillez vérifier l'URL",
            color: "warning",
            timeout: 4500,
          });
        }
        if (response.status === 400) {
          this.showAlert({
            message: "La campagne n'est pas active",
            color: "warning",
            timeout: 4500,
          });
        }
        if (response.status === 401) {
          this.showAlert({
            message: "Ce coupon est un coupon périmé",
            color: "warning",
            timeout: 4500,
          });
        }
        if (response.status >= 500) {
          this.showAlert({
            message: "Problème serveur, réessayez ultérieurement",
            color: "error",
            timeout: 4500,
          });
        }
      }
      this.loading = false;
    },
    resetCoupon() {
      this.dialog = false;
      this.$emit("reset");
    },
  },
});
