import Association from "@/interfaces/Association";
import Postcode from "@/interfaces/Postcode";
import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const formatDateFromIsoDateString = (dateIsoString: string): string => {
  const date = new Date(dateIsoString);
  let string = date.toLocaleDateString("FR-fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  if (string.startsWith("1")) {
    string = string.replace("1 ", "1er ");
  }
  return string;
};

export default new Vuex.Store({
  state: {
    dates: {
      isActive: false,
      dateOldest: "x",
      dateYoungest: "x",
      promoStartDate: "x",
      promoEndDate: "x",
      promoDueDate: "x",
    },
    rawDates: {
      dateOldest: null,
      dateYoungest: null,
    },
    textFields: {
      headerTitle: "Coupon association Quercy Caussadais",
    },
    coupon: {
      currentStep: 1,
      token: "",
    },
    alert: {
      message: "",
      color: "",
      show: false,
    },
    postcodes: [] as Array<Postcode>,
    associations: [] as Array<Association>,
  },
  mutations: {
    CURRENT_STEP(state, payload: number) {
      state.coupon.currentStep = payload;
    },
    TOKEN(state, payload: string) {
      state.coupon.token = payload;
    },
    ALERT(state, payload) {
      state.alert = { ...payload };
    },
    DATES(state, payload) {
      state.dates = { ...payload };
    },
    RAWDATES(state, payload) {
      state.rawDates = { ...payload };
    },
    POSTCODES(state, payload) {
      state.postcodes = [...payload];
    },
    ASSOCIATIONS(state, payload) {
      state.associations = [...payload];
    },
  },
  actions: {
    updateStep(context, payload: number) {
      context.commit("CURRENT_STEP", payload);
    },
    updateToken(context, payload: string) {
      context.commit("TOKEN", payload);
    },
    async updateDates(context) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/config`;
        const response = await fetch(route, {
          method: "GET",
        });
        if (!(response.status === 200)) {
          context.commit("showAlert", {
            message:
              "Le serveur n'est pas en mesure de traiter les demandes actuellement",
            color: "error",
            timeout: 3000,
          });
        } else {
          const dates = await response.json();
          const formattedDates = {
            isActive: dates.isActive,
            dateOldest: formatDateFromIsoDateString(dates.dateOldest),
            dateYoungest: formatDateFromIsoDateString(dates.dateYoungest),
            promoStartDate: formatDateFromIsoDateString(dates.promoStartDate),
            promoEndDate: formatDateFromIsoDateString(dates.promoEndDate),
            promoDueDate: formatDateFromIsoDateString(dates.promoDueDate),
          };
          const rawDates = {
            dateOldest: new Date(dates.dateOldest),
            dateYoungest: new Date(dates.dateYoungest),
          };
          context.commit("DATES", formattedDates);
          context.commit("RAWDATES", rawDates);
        }
      } catch (error) {
        context.commit("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
      }
    },
    showAlert(
      context,
      payload: { message: string; color: string; timeout: number },
    ) {
      context.commit("ALERT", {
        message: payload.message,
        color: payload.color,
        show: true,
      });
      setTimeout(() => {
        context.commit("ALERT", {
          message: payload.message,
          color: payload.color,
          show: false,
        });
        setTimeout(() => {
          context.commit("ALERT", { message: "", color: "", show: false });
        }, 500);
      }, payload.timeout);
    },
    resetCoupon(context) {
      context.commit("TOKEN", "");
      context.commit("CURRENT_STEP", 1);
      router.push("/");
    },
    async getPostcodes(context) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/postcode`;
        const response = await fetch(route, {
          method: "GET",
        });
        if (!(response.status === 200)) {
          context.dispatch("showAlert", {
            message:
              "Le serveur n'est pas en mesure de traiter les demandes actuellement",
            color: "error",
            timeout: 3000,
          });
        } else {
          const postcodes = await response.json();
          context.commit("POSTCODES", postcodes);
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
      }
    },
    async getAssociations(context) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/association`;
        const response = await fetch(route, {
          method: "GET",
        });
        if (!(response.status === 200)) {
          context.dispatch("showAlert", {
            message:
              "Le serveur n'est pas en mesure de traiter les demandes actuellement",
            color: "error",
            timeout: 3000,
          });
        } else {
          const associations = await response.json();
          context.commit("ASSOCIATIONS", [...associations]);
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
      }
    },
  },
  modules: {},
});
