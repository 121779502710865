









































































import Vue from "vue";
import { mapState } from "vuex";

import Stepper from "../components/Stepper.vue";

export default Vue.extend({
  name: "Home",
  data: () => {
    return {
      cards: [
        {
          img: "Picto_Step_01.png",
          text: "Je remplis le formulaire d'inscription et dépose les justificatifs. Je génère mon coupon en validant ma demande",
        },
        {
          img: "Picto_Step_02.png",
          text: "Je télécharge mon coupon et je le reçois par mail.",
        },
        {
          img: "Picto_Step_03.png",
          text: "Je transmets à mon association la version numérique ou la version papier.",
        },
      ],
    };
  },
  computed: {
    ...mapState(["coupon"]),
  },
  components: {
    Stepper,
  },
  methods: {},
});
