






























































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["textFields"]),
  },
  methods: {
    textSize(plus: boolean) {
      const htmlElement = document.querySelector("html");
      if (htmlElement) {
        if (window.navigator.userAgent.includes("Firefox")) {
          if (!htmlElement.style.fontSize) {
            htmlElement.style.fontSize = plus ? "1.1rem" : "1rem";
          } else {
            let parseSize = parseFloat(
              htmlElement.style.fontSize.replace("rem", ""),
            );
            if (plus) {
              if (parseSize < 1.6) {
                parseSize += 0.2;
              }
            } else {
              if (parseSize > 1) {
                parseSize = 1;
              }
            }
            htmlElement.style.fontSize = `${parseSize}rem`;
          }
        } else {
          if (!htmlElement.style.zoom) {
            htmlElement.style.zoom = plus ? "1.1" : "1";
          } else {
            let parseSize = parseFloat(htmlElement.style.zoom);
            if (plus) {
              if (parseSize < 1.6) {
                parseSize += 0.2;
              }
            } else {
              if (parseSize > 1) {
                parseSize = 1;
              }
            }
            htmlElement.style.zoom = `${parseSize}`;
          }
        }
      }
    },
  },
});
