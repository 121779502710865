































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import StepOne from "@/components/steps/StepOne.vue";
import StepTwo from "@/components/steps/StepTwo.vue";
import StepThree from "@/components/steps/StepThree.vue";

export default Vue.extend({
  name: "Stepper",
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  computed: {
    ...mapState(["coupon"]),
    step: {
      get(): number {
        return this.coupon.currentStep;
      },
      async set(value: number) {
        await this.updateStep(value);
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions(["updateStep", "resetCoupon"]),
  },
});
