





















































































































































































































































































































































































































import City from "@/interfaces/City";
import Postcode from "@/interfaces/Postcode";
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Jimp from "jimp";

const birthdateRegex = new RegExp(
  /([0-9])([0-9][/\s-]?)([0-9])([0-9])[/\s-]?([1-2])([0-9])([0-9])([0-9])$/,
);
const emailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export default Vue.extend({
  computed: {
    ...mapState(["dates", "rawDates", "postcodes", "associations", "coupon"]),
    birthdate: {
      get() {
        return this.raw.birthdate;
      },
      set(value: string) {
        if (value.length < 11) {
          this.raw.birthdate = value;
          // check if valid formatting and replace data.birthdate
          if (birthdateRegex.test(value)) {
            // We try to parse it as a date
            const raw = value.replace(/[/\-\s]/g, "");
            const dateFields = {
              day: raw.slice(0, 2),
              month: raw.slice(2, 4),
              year: raw.slice(4),
            };
            try {
              const date = new Date(
                `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
              );
              if (!isNaN(date.getTime())) {
                this.raw.birthdate = `${dateFields.day}/${dateFields.month}/${dateFields.year}`;
                this.form.data.birthdate = `${dateFields.year}-${dateFields.month}-${dateFields.day}`;
              } else {
                throw { message: "Invalid date" };
              }
            } catch (error: unknown) {
              console.error((error as Error).message);
            }
          }
        } else {
          this.raw.birthdate = this.raw.birthdate.slice(
            0,
            this.raw.birthdate.length - 1,
          );
        }
      },
    },
  },
  methods: {
    ...mapActions(["updateToken"]),
    async setFile(e: File, key: string) {
      const files = this.form.files;
      if (e.type === "application/pdf") {
        files[key as keyof typeof files] = e;
      } else {
        // TBD
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tmp = await Jimp.read(await e.arrayBuffer());
        tmp.resize(960, Jimp.AUTO);
        tmp.quality(60);
        const buffer = await tmp.getBufferAsync(Jimp.MIME_JPEG);
        const data = Uint8Array.from(buffer);
        const content = new File(
          [data.buffer],
          `${
            e.name.split(".").length > 1
              ? e.name.split(".").slice(0, -1).join(".")
              : e.name
          }`,
          {
            type: Jimp.MIME_JPEG,
          },
        );
        files[key as keyof typeof files] = content;
        // const a = document.createElement("a");
        // const uri = URL.createObjectURL(content);
        // a.href = uri;
        // a.download = "test.jpg";
        // a.click();
      }
    },
    updateCities(val: number) {
      // We filter get the postcode object from its ID :
      const postcode = (this.postcodes as Array<Postcode>).find((value) => {
        return value.id === val;
      });
      if (postcode && postcode.Cities) {
        this.raw.cities = postcode.Cities;
      }
    },
    async sendCoupon() {
      if ((this.$refs.formCoupon as HTMLFormElement).validate()) {
        this.loading = true;

        // Preparing the JSON part of the FormData
        const object = {
          firstname: this.form.data.firstname,
          lastname: this.form.data.lastname,
          birthdate: this.form.data.birthdate,
          address: this.form.data.address,
          placeOfBirth: this.form.data.placeOfBirth,
          phone: this.form.data.phone,
          email: this.form.data.email,
          association_id: this.form.data.associationId,
          associationOther: this.form.data.associationOther,
          city_id: this.form.data.cityId,
          postcode_id: this.form.data.postcodeId,
        };
        // Setting the endpoint and headers
        const route = `${process.env.VUE_APP_API_HOST}/coupon`;
        const data = new FormData();

        // Adding the Files and object as "coupon"
        if (this.form.files.idDocument) {
          data.append(
            "idDocument",
            this.form.files.idDocument as unknown as File,
          );
        }
        if (this.form.files.familyRecord) {
          data.append(
            "familyRecord",
            this.form.files.familyRecord as unknown as File,
          );
        }
        data.append(
          "proofOfAddress",
          this.form.files.proofOfAddress as unknown as File,
        );
        data.append("coupon", JSON.stringify(object));
        try {
          const response = await fetch(route, {
            method: "POST",
            body: data,
          });
          if (response.status === 200) {
            const object = await response.json();
            // We store the token
            await this.$store.dispatch("updateToken", object.uuid);
            // We can reset the form
            this.form = {
              accept: false,
              data: {
                firstname: "",
                lastname: "",
                birthdate: "" as string,
                address: "",
                placeOfBirth: "",
                phone: "",
                email: "",
                confirmEmail: "",
                associationId: 0,
                associationOther: "",
                cityId: 0,
                postcodeId: 0,
                swear: false,
              },
              files: {
                idDocument: null,
                familyRecord: null,
                proofOfAddress: null,
              },
            };
            this.raw = {
              birthdate: "",
              cities: [] as Array<City>,
              confirmEmail: "",
            };
            (this.$refs.formCoupon as Record<string, any>).resetValidation();
            // Once stored, we can push the new nav
            this.$router.push(`/?step=3&token=${this.coupon.token}`);
          } else {
            if (response.status === 400) {
              this.$store.dispatch("showAlert", {
                message:
                  "Les données fournies posent problème, vérifiez et réessayez",
                color: "warning",
                timeout: 5000,
              });
            } else {
              const object = await response.json();
              if (
                object.message.toLowerCase() == "Validation Error".toLowerCase()
              ) {
                this.$store.dispatch("showAlert", {
                  message: "Un coupon existe déjà pour cet enfant!",
                  color: "warning",
                  timeout: 5000,
                });
              } else {
                this.$store.dispatch("showAlert", {
                  message:
                    "Quelque chose s'est mal passé, veuillez réessayer ultérieurement",
                  color: "error",
                  timeout: 5000,
                });
              }
            }
          }
          this.loading = false;
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message:
              "Quelque chose s'est mal passé, veuillez réessayer ultérieurement",
            color: "error",
            timeout: 5000,
          });
          this.loading = false;
        }
      } else {
        this.supportingPanel = 0;
      }
    },
  },
  data: () => ({
    supportingPanel: undefined as number | undefined,
    loading: false,
    svg: {
      arrow:
        "M 17.56 8.872 a 0.904 0.904 90 0 0 0 -1.28 L 17.024 7.056 a 0.904 0.904 90 0 0 -1.28 0 L 9.56 13.24 L 3.376 7.056 a 0.904 0.904 90 0 0 -1.28 0 L 1.568 7.592 a 0.904 0.904 90 0 0 0 1.28 L 8.92 16.232 a 0.904 0.904 90 0 0 1.28 0 Z",
      info: "M 9 15 H 11 V 9 H 9 Z M 10 0 a 10 10 90 1 0 10 10 A 10.032 10.032 90 0 0 10 0 Z m 0 18 A 8 8 90 1 1 18 10 A 8.02 8.02 90 0 1 10 18 Z M 9 7 H 11 V 5 H 9 Z m 0 0",
    },
    formValid: false,
    form: {
      accept: false,
      data: {
        firstname: "",
        lastname: "",
        birthdate: "" as string,
        address: "",
        placeOfBirth: "",
        phone: "",
        email: "",
        confirmEmail: "",
        associationId: 0,
        associationOther: "",
        cityId: 0,
        postcodeId: 0,
        swear: false,
      },
      files: {
        idDocument: null as null | File | Blob,
        familyRecord: null as null | File | Blob,
        proofOfAddress: null as null | File | Blob,
      },
    },
    raw: {
      birthdate: "",
      cities: [] as Array<City>,
      confirmEmail: "",
    },
    rules: {
      files: (context: any) => {
        const common = [
          (val: File) =>
            (!!val &&
              (val.type === "image/png" ||
                val.type === "image/jpeg" ||
                val.type === "application/pdf")) ||
            !val ||
            "Le type de fichier est incorrect",
          (val: File) =>
            (!!val && val.size < 10 * 1024 * 1024) ||
            !val ||
            "Le fichier est trop volumineux",
          (val: File) =>
            val && val.type === "application/pdf"
              ? (!!val && val.size < 1.5 * 1024 * 1024) ||
                !val ||
                "Le fichier est trop volumineux"
              : true,
        ];
        return {
          idDocument: [
            (val: File) =>
              !!context.form.files.familyRecord ||
              !!val ||
              "Sélectionnez soit une CNI soit un livret de famille",
            // Type checking
            ...common,
          ],
          familyRecord: [
            (val: File) =>
              !!context.form.files.idDocument ||
              !!val ||
              "Sélectionnez soit une CNI soit un livret de famille",
            ...common,
          ],
          proofOfAddress: [
            (val: File) =>
              !!val || "Joignez impérativement un justificatif de domicile",
            ...common,
          ],
        };
      },
      name: [
        (val: string) => !!val.length || "Entrez un nom",
        (val: string) => val.length > 1 || "Ce nom paraît court",
        (val: string) => val.length < 250 || "Ce nom est trop long",
      ],
      email: [
        (val: string) => !!val.length || "Veuillez entrer votre email",
        (val: string) => emailRegex.test(val) || "Cet email semble invalide",
      ],
      confirmEmail: (context: any) => [
        (val: string) =>
          (val &&
            context.form.data.email &&
            (context.form.data.email as string).toLowerCase() ===
              val.toLowerCase()) ||
          "L'email ne correspond pas",
      ],
      address: [(val: string) => !!val.length || "Entrez votre addresse"],
      placeOfBirth: [
        (val: string) =>
          !!val.length || "Entrez le lieu de naissance de l'enfant",
      ],
      birthdate: (context: any) => {
        return [
          (value: string) => {
            if (birthdateRegex.test(value)) {
              // We try to parse it as a date
              const raw = value.replace(/[/\-\s]/g, "");
              const dateFields = {
                day: raw.slice(0, 2),
                month: raw.slice(2, 4),
                year: raw.slice(4),
              };
              try {
                const date = new Date(
                  `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
                );
                return (
                  !isNaN(date.getTime()) ||
                  "Ce n'est pas une date de naissance valide"
                );
              } catch (error) {
                return "Ce n'est pas une date de naissance valide";
              }
            } else {
              return "Veuillez entrer une date de naissance complète";
            }
          },
          (value: string) => {
            if (birthdateRegex.test(value)) {
              // We try to parse it as a date
              const raw = value.replace(/[/\-\s]/g, "");
              const dateFields = {
                day: raw.slice(0, 2),
                month: raw.slice(2, 4),
                year: raw.slice(4),
              };
              try {
                return (
                  new Date(
                    `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
                  ) > new Date(context.rawDates.dateOldest) ||
                  `L'enfant doit être né après le ${context.dates.dateOldest}`
                );
              } catch (error) {
                return "Ce n'est pas une date de naissance valide";
              }
            } else {
              return "Veuillez entrer une date de naissance complète";
            }
          },
          (value: string) => {
            if (birthdateRegex.test(value)) {
              // We try to parse it as a date
              const raw = value.replace(/[/\-\s]/g, "");
              const dateFields = {
                day: raw.slice(0, 2),
                month: raw.slice(2, 4),
                year: raw.slice(4),
              };
              try {
                return (
                  new Date(
                    `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
                  ) < new Date(context.rawDates.dateYoungest) ||
                  `L'enfant doit être né avant le ${context.dates.dateYoungest}`
                );
              } catch (error) {
                return "Ce n'est pas une date de naissance valide";
              }
            } else {
              return "Veuillez entrer une date de naissance complète";
            }
          },
        ];
      },
      postcodes: [(val: number) => !!val || "Choisissez un code postal"],
      cities: [(val: number) => !!val || "Choisissez une ville"],
      associations: [
        (val: number) => !!val || val === -1 || "Choisissez une association",
      ],
      associationOther: (context: any) => [
        (val: string) =>
          !(context.form.data.associationId === -1 && !val) ||
          "Entrez le nom de l'association",
      ],
      phone: [
        (val: string) => !!val.length || "Entrez votre numéro de contact",
        (val: string) =>
          /0[1-9][-/.\s]?([0-9]{2}[-/.\s]?){4}$/.test(val) ||
          "Ce numéro de téléphone semble invalide",
      ],
      checkbox: [(val: boolean) => val || "Veuillez accepter les conditions"],
    },
  }),
  beforeMount() {
    if (this.rawDates.dateOldest) {
      this.form.data.birthdate = (this.rawDates.dateOldest as Date)
        .toISOString()
        .split("T")[0];
    }
  },
  watch: {
    rawDates: {
      deep: true,
      handler() {
        this.form.data.birthdate = (this.rawDates.dateOldest as Date)
          .toISOString()
          .split("T")[0];
      },
    },
  },
});
