
































import Vue from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState, mapActions } from "vuex";
import failsafe from "@/modules/failsafe";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    backToTopVisible: false,
    failsafe: false,
    //
  }),
  computed: {
    ...mapState(["alert", "dates"]),
  },
  methods: {
    ...mapActions([
      "showAlert",
      "updateDates",
      "getPostcodes",
      "getAssociations",
    ]),
    backToTop() {
      this.$vuetify.goTo(0);
    },
  },
  async beforeMount() {
    //this.failsafe = await failsafe("cXVlcmN5Y2F1c3NhZGFpcw==");
    console.clear();
    this.updateDates();
    this.getPostcodes();
    this.getAssociations();
  },
  created() {
    window.onscroll = () => {
      if (window.scrollY > 300) {
        this.backToTopVisible = true;
      } else {
        this.backToTopVisible = false;
      }
    };
  },
});
